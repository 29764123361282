<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    pt: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pagamento',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'Aguardando pagamento',
      'Payment confirmed': 'Pagamento confirmado',

      'Address': 'Endereço',
      'Send exactly value to make payment': 'Envie exatamente o valor para efetuar o pagamento',

      'The order was not found.': 'O pedido não foi encontrado.',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Order': 'Pedido',
      'Payment Method': 'Forma de Pago',
      'Order Date': 'Data do Pedido',

      'Items': 'Itens',
      'Item': 'Item',
      'Price': 'Preço',
      'Qty': 'Qtd',
      'Value': 'Valor',
      'Status': 'Status',
      'Date': 'Data',

      'Switch plans': 'Trocar de Plano',
      'Current choice': 'Escolha Atual',
      'Choose': 'Escolher',
      'Plan name': 'Nome do Plano',
      'Plans': 'Planos',

      'Awaiting payment': 'En espera de pago',
      'Payment confirmed': 'Pago confirmado',

      'Address': 'Dirección',
      'Send exactly value to make payment': 'Enviar exactamente el valor de hacer el pago',

      'The order was not found.': 'O pedido não foi encontrado.',

      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        date: null,

        subtotal: '0.00',
        shipping: {
          method: '',
          value: '0.00',
          address: {
            zipcode: '',
            address: '',
            number: '',
            complement: '',
            district: '',
            city: '',
            state: '',
          }
        },
        payment: {
          methods: [],
          list: [],
        },

        total: '0.00',

        items: null,
        status: null,
      },

      balance: {
        modal: false,
        loading: false,
        pay: '0.00',
        total: '0.00',
      },

      creditcard: {
        loading: false,
        modal: false,
        ip: '',
        installments: [],
        installment: '1',
        number: '',
        holderName: '',
        expiryMonth: '',
        expiryYear: '',
        cvv: '',
      },
    };
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get('inventory/orders/' + this.order.id)
          .then(response => {
            if (response.data.status == 'success') {
              this.order = response.data.order

              if (this.order.payment.status == 'pending') {
                this.creditcard.installments = this.order.payment.methods.creditcard.installments
              }

              this.loading = false
            } else {
              this.empty = true
              this.message = response.data.message
            }
          })
          .catch(error => {
            this.errored = error
          })
      }
    },
    payCreditCard() {
      this.creditcard.loading = true;
      this.creditcard.modal = true;

      api
        .post('inventory/orders/' + this.order.id + '/creditcard', {
          installment: this.creditcard.installment,
          number: this.creditcard.number,
          holderName: this.creditcard.holderName,
          expiryMonth: this.creditcard.expiryMonth,
          expiryYear: this.creditcard.expiryYear,
          cvv: this.creditcard.cvv,
          ip: this.creditcard.ip,
        })
        .then(response => {
          if (response.data.status == 'success') {
            this.$toast.success('O pedido foi pago com sucesso.')

            this.creditcard.loading = false;
            this.creditcard.modal = false;
            this.getOrder();
          } else {
            this.creditcard.loading = false;
            this.creditcard.modal = false;
            this.$toast.error(response.data.message)
          }
        })
        .catch(error => {
          if (error) {
            this.creditcard.loading = false;
            this.creditcard.modal = false;
            this.$toast.error('Ocorreu um erro ao processar o pagamento, tente novamente.')
          }
        })
        .finally(() => {
          this.creditcard.loading = false;
          this.creditcard.modal = false;
        })
    },
    getIp() {
      fetch('https://api.ipify.org?format=json')
        .then(response => response.json())
        .then(data => {
          if (data.ip) {
            this.creditcard.ip = data.ip;
          }
        });
    },
  },
  mounted() {
    this.getOrder()
    this.getIp()
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/inventory/partners">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item"><router-link tag="a" to="/inventory/partners">Reposição</router-link></li>
      <li class="breadcrumb-item"><router-link tag="a" to="/inventory/orders">Meus Pedidos</router-link></li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between">
      <div>
        <h3>Meus Pedidos</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div v-if="order.partner && order.partner.name" class="card">
          <div class="card-body card-partner">
            <div class="d-flex align-items-center">
              <div class="logo">
                <img v-if="order.partner.image.logo" :src="order.partner.image.logo">
                <img v-else src="@/assets/images/logo-app.png" />
              </div>
              <div>
                <h5 :class="!order.partner.description ? 'mb-0' : ''">{{ order.partner.name }}</h5>
                <p v-if="order.partner.description" class="mb-0">{{ order.partner.description }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body p-4">
            <div v-if="errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="empty">{{ t('No records found.') }}</div>
            <div v-else class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">
                  #{{ order.id }}
                </h4>
                <h4 class="mb-0 font-size-15 flex-fill text-right">
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th class="border-0 text-uppercase">{{ t('Item') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Price') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Qty') }}</th>
                      <th class="border-0 text-uppercase">{{ t('Total') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td, index) in order.items" :key="index">
                      <td>{{ td.name }} </td>
                      <td>{{ td.price | currency }}</td>
                      <td>{{ td.quantity }}</td>
                      <td>{{ td.subtotal | currency }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6 mb-4">
                  <div v-if="order.shipping.address.zipcode">
                    <h3 class="font-size-16 mb-3">{{ t('Endereço de entrega') }}</h3>
                    {{ order.shipping.address.address }},
                    {{ order.shipping.address.number }},
                    <span v-if="order.shipping.address.complement">{{ order.shipping.address.complement }}, </span>
                    {{ order.shipping.address.district }}<br>
                    {{ order.shipping.address.city }}/{{ order.shipping.address.state }},
                    {{ order.shipping.address.zipcode }}
                  </div>
                  <a v-if="order.shipping.tracking && order.shipping.tracking.code" target="_blank" :href="order.shipping.tracking.url" class="btn btn-default btn-sm mt-1 p-2 align-middle">
                    <i class="bx bxs-truck font-size-17 text-white mr-1 align-top"></i>
                    Rastrear pedido
                  </a>
                </div>
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-3">{{ t('Detalhes do pagamento') }}</h3>
                  <div class="table-responsive">
                    <table class="table table-nowrap table-borderless">
                      <tbody>
                        <tr v-if="order.subtotal != order.total">
                          <td class="px-0 py-1">{{ t('SUBTOTAL') }}</td>
                          <td class="px-0 py-1 text-right">{{ order.subtotal | currency }}</td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1">{{ t('FRETE') }} — {{ order.shipping.method }}</td>
                          <td class="px-0 py-1 text-right">+ {{ order.shipping.value | currency }}</td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1 font-weight-bold">{{ t('TOTAL') }}</td>
                          <td class="px-0 py-1 font-weight-bold text-right">{{ order.total | currency }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h3 class="font-size-16 mb-2">{{ t('Status') }}</h3>
              <div v-for="(td, index) in order.status" :key="index">
                {{ td.date }} — {{ t(td.status) }}
              </div>
              <b-tabs v-if="order.payment.status === 'pending'" justified nav-class="nav-tabs-custom mt-5" content-class="p-3 text-muted">
                <b-tab v-for="(pay, method) in order.payment.methods" :key="method">
                  <template v-slot:title>{{ pay.name }}</template>
                  <div v-if="method == 'pix'" class="text-center">
                    <div v-if="pay.pix && pay.pix.text">
                      <div v-if="pay.pix.image">
                        <div>
                          Use o código QR para prosseguir com a transação.
                        </div>
                        <div class="my-4 d-flex justify-content-center">
                          <div class="border rounded p-3">
                            <img style="width: 180px" :src="pay.pix.image" />
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div>
                          Use o Pix Copia e Cola para prosseguir com a transação.
                        </div>
                        <div class="my-4">
                          <b-form-textarea id="textarea-auto-height" rows="3" max-rows="8" v-model="pay.pix.text" readonly></b-form-textarea>
                        </div>
                      </div>
                      <div>
                        <button class="btn btn-default btn-lg btn-block text-uppercase" v-clipboard:copy="pay.pix.text"
                          v-on:click="$toast.success('<strong>Copiado para a área de transferência.</strong><br>Agora, tudo o que você precisa fazer é acessar o aplicativo do seu banco e concluir o processo usando o <strong>Pix Copia e Cola</strong>.')">
                          Copiar Pix Copia e Cola
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else-if="method == 'creditcard'">
                    <div class="row justify-content-center">
                      <b-form class="col-md-6" @submit.prevent="payCreditCard">
                        <div class="my-5">
                          <b-form-group label="Número de Parcelas" label-for="creditcard.installment">
                            <b-form-select id="installment" v-model="creditcard.installment" :options="creditcard.installments" required></b-form-select>
                          </b-form-group>
                          <b-form-group label="Número do Cartão" label-for="number">
                            <b-form-input id="number" v-model="creditcard.number" type="text" required v-mask="'#### #### #### ####'"></b-form-input>
                          </b-form-group>
                          <b-form-group label="Nome do Titular" label-for="holderName">
                            <b-form-input id="holderName" v-model="creditcard.holderName" type="text" required></b-form-input>
                          </b-form-group>
                          <div class="row">
                            <div class="col-12 col-md-6">
                              <b-form-group label="Vencimento" label-for="expiryDate">
                                <b-input-group>
                                  <b-form-input id="expiryMonth" v-model="creditcard.expiryMonth" type="text" placeholder="Mês" maxlength="2" required v-mask="'##'"></b-form-input>
                                  <b-form-input id="expiryYear" v-model="creditcard.expiryYear" type="text" placeholder="Ano" maxlength="2" required v-mask="'##'"></b-form-input>
                                </b-input-group>
                              </b-form-group>
                            </div>
                            <div class="col-12 col-md-6">
                              <b-form-group label="Código de Segurança" label-for="cvv">
                                <b-form-input id="cvv" v-model="creditcard.cvv" type="text" required v-mask="'###'"></b-form-input>
                              </b-form-group>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                          <button class="btn btn-default btn-lg mr-3" type="submit" :disabled="creditcard.loading">
                            Pagar
                            <b-spinner v-if="creditcard.loading" small class="ml-2 align-middle" variant="white" role="status"></b-spinner>
                          </button>
                        </div>
                      </b-form>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="creditcard.modal" modal-class="modal-center" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc>
      <div class="text-center py-4">
        <div class="d-flex justify-content-center align-items-center mb-4">
          <b-spinner large class="align-middle" variant="default" role="status"></b-spinner>
        </div>
        <h5>Aguarde!</h5>
        <h6>Estamos processando o seu pagamento!</h6>
      </div>
    </b-modal>
  </Layout>
</template>

<style scoped>
.card-partner .d-flex {
  gap: 10px;
}

.card-partner .logo img {
  border-radius: 50%;
  width: 100px;
}
</style>
